import React, { useEffect, useRef } from "react";
import gsap from 'gsap';


export interface IntroImageProps {
    imageIndex: number,
    rotate: number
}

export default function IntroImage(props: IntroImageProps) {
    const cardRef = useRef(null);

    function handleCardClick() {
        const card = cardRef.current;
        gsap.fromTo(card, { rotation: '+=10' }, { rotation: '-=10', duration: 0.6, ease: 'bounce' })
    }

    useEffect(() => {
        const card = cardRef.current;
        const deg = Math.random()*30 - 15;

        gsap.fromTo(card, { opacity: 0, y: 50, rotation: 0 }, {
            opacity: 1, y: 0, rotation: deg, duration: 0.6, scrollTrigger: {
                trigger: card
            }
        })

    }, [])

    return (
        <div className="feature_image">
            <img
                ref={cardRef}
                alt="card"
                className="card"
                onClick={(e) => handleCardClick()}
                src={require(`../../../assets/card_${props.imageIndex}.png`)}
                style={{ transform: `rotate(${props.rotate}deg)` }}
            />
        </div>
    );
}