import React from "react"
import { Link } from "react-router-dom";
import './index.css';

export default function Footer() {
    const iconDiscord = require('../../assets/button_discord.png')
    // const iconFacebook = require('../../assets/button_facebook.png')
    const iconInstagram = require('../../assets/button_instagram.png')
    const iconTiktok = require('../../assets/button_tiktok.png')
    const iconTwitter = require('../../assets/button_twitter.png')
    const iconYoutube = require('../../assets/button_youtube.png')

    return (
        <footer className="footer">
            <div className="footer-title">
                follow us!
            </div>
            <div className="contacts-container div-center">
                <a href="https://discord.gg/g8YS4WAVqZ" target="_blank" rel="noreferrer">
                    <img alt='discord' src={iconDiscord} />
                </a>
                <a href="https://twitter.com/BonmusicHQ" target="_blank" rel="noreferrer">
                    <img alt='twitter' src={iconTwitter} />
                </a>
                <a href="https://www.tiktok.com/@hiya_bonmusic" target="_blank" rel="noreferrer">
                    <img alt='tiktok' src={iconTiktok} />
                </a>
                <a href="https://www.instagram.com/hi_bonmusic/" target="_blank" rel="noreferrer">
                    <img alt='instagram' src={iconInstagram} />
                </a>
                <a href="https://www.youtube.com/channel/UCCSaXMdc2VmV8ze1umrhkbQ" target="_blank" rel="noreferrer">
                    <img alt='youtube' src={iconYoutube} />
                </a>
            </div>
            <div className="footer-notes">
                <div>
                    <Link to="/privacy-policy">Privacy Policy</Link>  |  <Link to="/terms">Terms of Usage</Link>
                </div>
                <div>
                    © {window.location.host === 'bonbonbongame.com' ? '北京棒棒棒科技有限公司' : 'Nice Stick Technology Limited'}
                </div>
            </div>
        </footer>
    )
}