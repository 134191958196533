import React from 'react';
import ReactDOM from 'react-dom/client';
import Banner from './components/banner';
import ButtonJoin from './components/buttonJoin';
import Intro from './components/intro';
import Video from './components/video';
import Birthday from './components/birthday';
import Characters from './components/characters';
import Sounds from './components/sounds';
import Footer from './components/footer';
import SignUpPopUp from './components/signup';
import Links from './components/links';
// import ClosedBetaNotice from './components/closedBetaNotice';
import Terms from './components/terms';
import PrivacyPolicy from './components/privacyPolicy'
import Modal from 'react-modal';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import './index.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function Main() {

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function handleClick() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <div className="main">
      <Banner />
      <Video />
      <Links/>
      <Intro />
      <Birthday />
      <Characters />
      <Sounds />
      {/* <ButtonJoin handleClick={() => handleClick()} /> */}
      <Footer />
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={modalIsOpen}
        onRequestClose={() => handleClose()}
        closeTimeoutMS={200}
        ariaHideApp={false}
      >
        <SignUpPopUp
          onRequestClose={() => handleClose()}
        />
      </Modal>
      {/* <ClosedBetaNotice /> */}

    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Routes>
        <Route path="terms" element={<Terms />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/" element={<Main />} />
      </Routes>
  </BrowserRouter>
  // </React.StrictMode>
);
