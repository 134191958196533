import React from "react";
import IntroImage, { IntroImageProps } from "./introImage";
import {useRef, useEffect} from 'react';
import gsap from 'gsap';

interface IntroRowProps {
    title: string,
    notion: string,
    content: () => JSX.Element
    flexDirection: 'row' | 'row-reverse'
}

export default function IntroRow(props: IntroRowProps & IntroImageProps) {
    const titleRef = useRef(null);
    const notionRef = useRef(null);

    useEffect(() => {
        const title = titleRef.current;
        const notion = notionRef.current;
  
        gsap.fromTo(title, {opacity:0, y:50}, {opacity:1, y:0, duration:0.6, scrollTrigger:{
          trigger:title
        }})
        gsap.fromTo(notion, {opacity:0, y:50}, {opacity:1, y:0, duration:0.6, delay:0.2, scrollTrigger:{
          trigger:notion
        }})
      }, [])

    return (
        <div className="intro_row" style={{ flexDirection: props.flexDirection }}>
            <div className="feature">
                <div  className="feature_header">
                <p ref={titleRef} className="feature_title"> {props.title}</p>
                <p ref={notionRef} className="feature_notion"> {props.notion} </p>
                </div>
                <div className="feature_content">
                    <props.content />
                </div>
            </div>
            <IntroImage imageIndex={props.imageIndex} rotate={props.rotate} />
        </div>
    );
}