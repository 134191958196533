import React, { useEffect, useRef } from "react";
import { isInChina } from "../../utils";
import './index.css';

export default function Video() {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        isInChina((isChina) => {
            if (isChina) {
                videoRef.current!.style.display = 'inline'
            } else {
                iframeRef.current!.style.display = 'inline'
            }
        })
    }, [])

    return (
        <div className="trailer-container">
            <img alt="echo_pull" src={require('../../assets/echo_pull.png')} />
            <iframe
                title='video'
                className="trailer-video"
                ref={iframeRef}
                src="https://www.youtube.com/embed/pWvUB9mTpIo"
                style={{ display: 'none' }}
            ></iframe>
            <video
                title='video'
                className="trailer-video"
                ref={videoRef}
                src="https://mpv.videocc.net/f376941452/c/f376941452dead61db44bca3c7519e2c_3.mp4"
                poster={require('../../assets/poster.png')}
                style={{ display: 'none' }}
                controls={true}
            ></video>
        </div>
    )
}