import React from "react"
import './index.css';

export default function Characters() {
    const avatarChirpie = require('../../assets/characters/avatar_chirpie.png')
    const avatarDali = require('../../assets/characters/avatar_dali.png')
    const avatarDonut = require('../../assets/characters/avatar_donut.png')
    const avatarEchoDelay = require('../../assets/characters/avatar_echo&delay.png')
    const avatarFann = require('../../assets/characters/avatar_fann.png')
    const avatarFluffy = require('../../assets/characters/avatar_fluffy.png')
    const avatarFuyu = require('../../assets/characters/avatar_fuyu.png')
    const avatarKarl = require('../../assets/characters/avatar_karl.png')
    const avatarLulu = require('../../assets/characters/avatar_lulu.png')
    const avatarMaggie = require('../../assets/characters/avatar_maggie.png')
    const avatarMarsh = require('../../assets/characters/avatar_marsh.png')
    const avatarPisces = require('../../assets/characters/avatar_pisces.png')
    const avatarRappit = require('../../assets/characters/avatar_rappit.png')
    const avatarSandy = require('../../assets/characters/avatar_sandy.png')
    const avatarShojo = require('../../assets/characters/avatar_shojo.png')
    const avatarSpike = require('../../assets/characters/avatar_spike.png')
    // const avatarWako = require('../../assets/characters/avatar_wako.png')

    return (
        <div className="div-center">
            <div className="character-container">
                <div className="items-title">
                    with animals play your music
                </div>
                <div>
                    <img alt="chirpie" src={avatarChirpie} />
                    <img alt="dali" src={avatarDali} />
                    <img alt="donut" src={avatarDonut} />
                    <img alt="echo_delay" src={avatarEchoDelay} />
                    <img alt="fann" src={avatarFann} />
                    <img alt="fluffy" src={avatarFluffy} />
                    <img alt="fuyu" src={avatarFuyu} />
                    <img alt="karl" src={avatarKarl} />
                    <img alt="lulu" src={avatarLulu} />
                    <img alt="maggie" src={avatarMaggie} />
                    <img alt="marsh" src={avatarMarsh} />
                    <img alt="pisces" src={avatarPisces} />
                    <img alt="rappit" src={avatarRappit} />
                    <img alt="sandy" src={avatarSandy} />
                    <img alt="shojo" src={avatarShojo} />
                    <img alt="spike" src={avatarSpike} />
                </div>
                <div className="items-title">
                    and more...
                </div>
            </div>
        </div>
    )
}