import React from "react";
import "./index.css";
import { isMobile } from "pixi.js-legacy";

export default function Links() {
  const linkAppStore = require("../../assets/button_app_store.png");
  const linkGooglePlay = require("../../assets/button_google_play.png");
  const linkTaptap = require("../../assets/button_taptap.png");
  const linkKickStarter = require("../../assets/button_kickstarter.png")

  return (
    <div className="link_group">
      <div className="link-container div-center">
        <a
          href="https://apps.apple.com/us/app/bon-music/id1634773203"
          target="_blank"
          rel="noreferrer"
          style={{
            display: isMobile.phone && !isMobile.apple.device ? "none" : "block",
          }}
        >
          <img alt="app store" src={linkAppStore} />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=io.nicestick.bonmusic"
          target="_blank"
          rel="noreferrer"
          style={{
            display:
              isMobile.phone && !isMobile.android.device ? "none" : "block",
          }}
        >
          <img alt="google play" src={linkGooglePlay} />
        </a>
        <a
          href="https://www.taptap.io/app/234324/?hreflang=en_US"
          target="_blank"
          rel="noreferrer"
          style={{
            display:
              isMobile.phone && !isMobile.android.device ? "none" : "block",
          }}
        >
          <img alt="taptap" src={linkTaptap} />
        </a>
      </div>
      {/* <div className="link-container div-center">
        <a
          href="https://www.kickstarter.com/projects/nicestick/bon-music"
          target="_blank"
          rel="noreferrer"
          style={{
            maxWidth: "300px"
          }}
        >
          <img alt="kickstarter" src={linkKickStarter} />
        </a>
      </div> */}
    </div>
  );
}
