import React from "react";
import Content1 from "./introContent/content1";
import IntroRow from "./introRow";
import './index.css';
import Content4 from "./introContent/content4";
import Content2 from "./introContent/content2";
import Content3 from "./introContent/content3";

export default function Intro() {
  return (
    <div className="intro">
      <IntroRow
        title={'make music on your phone'}
        notion={'easier than ever'}
        rotate={-15}
        imageIndex={1}
        flexDirection={'row'}
        content={Content1}
      />

      <IntroRow
        title={'ultra beginner friendly'}
        notion={'piece of cake'}
        rotate={20}
        imageIndex={2}
        flexDirection={'row-reverse'}
        content={Content2}
      />

      <IntroRow
        title={'recruit your own animal band'}
        notion={'find your favourite characters'}
        rotate={-10}
        imageIndex={3}
        flexDirection={'row'}
        content={Content3}
      />

      <IntroRow
        title={'learn music theory with interactive lessons'}
        notion={'best lessons ever'}
        rotate={18}
        imageIndex={4}
        flexDirection={'row-reverse'}
        content={Content4}
      />
    </div>
  )
}