import React from "react";
import { useEffect, useRef } from "react";
import gsap from 'gsap';
import './index.css';

export default function Birthday() {
    const birthdayContainerRef = useRef(null);
    const birthdayHeadRef = useRef(null);
    const birthdayTextRef = useRef(null);

    function handleRoleClick() {
        const card = birthdayHeadRef.current;
        gsap.fromTo(card, { scale: '1' }, { scale: '1.1', duration: 0.6, ease:'bounce'})
        gsap.fromTo(card, { scale: '1.1' }, { scale: '1', duration: 0.6, ease:'bounce'})
    }

    useEffect(() => {
      const birthdayContainer = birthdayContainerRef.current;
      const birthdayHead = birthdayHeadRef.current;
      const birthdayText = birthdayTextRef.current;

      gsap.fromTo(birthdayContainer, {scaleY:0}, {scaleY:1, duration:0.3, delay:0.3, scrollTrigger:{
        trigger:birthdayContainer
      }})
      gsap.fromTo(birthdayHead, {scale:0}, {scale:1, duration:0.3, delay:0.5, ease:'circ',scrollTrigger:{
        trigger:birthdayContainer
      }})
      gsap.fromTo(birthdayText, {scale:0}, {scale:1, duration: 0.3, delay:0.5, scrollTrigger:{
        trigger:birthdayContainer
      }})
    }, [])

    return(
      <div ref={birthdayContainerRef} className="birthday-container div-center">
        <img alt="role" ref={birthdayHeadRef} className="birthday-head noselect" onClick={(e) => handleRoleClick()} src={require('../../assets/birthday/birthday_fluffy.png')} />
        <img alt="name" ref={birthdayTextRef} className="birthday-text" src={require('../../assets/birthday/fluffy_name.png')} />
      </div>
    )
  }