import React from "react";
import {useRef, useEffect} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function Content4(){
    const courseRhythmRef = useRef(null);
    const courseScalesRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const courseRhythm = courseRhythmRef.current;
        const courseScales = courseScalesRef.current;
        const container = containerRef.current;
  
        gsap.fromTo(courseRhythm, {y:80, opacity:0.5}, {y:0, opacity:1, duration: 0.6, scrollTrigger:{
          trigger:container
        }})

        gsap.fromTo(courseScales, {y:80, opacity:0.5}, {y:0, opacity:1, duration: 0.6, scrollTrigger:{
            trigger:container
          }})
      }, [])

    return(
        <div className="content4" ref={containerRef}>
            <img ref={courseRhythmRef} alt="course_rhythm" src={require("../../../assets/course_rhythm.png")}></img>
            <img ref={courseScalesRef} alt="course_scales" src={require("../../../assets/course_scales.png")}></img>
        </div>
    )
}