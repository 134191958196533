import React, { useEffect, useRef, useState } from "react";
import PixiStage from "./pixi";
import { isMobile } from "./player/utils";

export default function Content3() {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const pixiStageRef = useRef<ReturnType<typeof PixiStage>>();
    const playButtonRef = useRef<HTMLButtonElement>(null);

    const playButton = require("../../../assets/cursors/cursor_play.png")
    const pauseButton = require("../../../assets/cursors/cursor_pause.png")
    const [image, setImage] = useState(playButton)

    useEffect(() => {
        pixiStageRef.current = PixiStage(canvasRef.current!);

        if (isMobile()) {
            playButtonRef.current?.classList.toggle("mobile", true)
        }
    }, []);

    const handlePlayButtonClick = () => {
        if (pixiStageRef.current) {
            const playing = pixiStageRef.current.togglePlay()
            setImage(playing ? pauseButton : playButton)
        }
    }

    return (
        <div className="content3">
            <button>
                <img
                    alt="button_prev"
                    src={require("../../../assets/button_prev.png")}
                    onClick={() => pixiStageRef.current?.handlerPrev()}
                />
            </button>
            <canvas ref={canvasRef}></canvas>
            <button>
                <img
                    alt="button_next"
                    src={require("../../../assets/button_next.png")}
                    onClick={() => pixiStageRef.current?.handlerNext()}
                />
            </button>
            <button ref={playButtonRef}>
                <img
                    alt="button_next"
                    src={image}
                    onClick={() => handlePlayButtonClick()}
                />
            </button>
        </div>
    );
}
