import React from "react";
import { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function Content2() {
  const rhythmRef = useRef(null);
  const chordsRef = useRef(null);
  const melodyRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const rhythm = rhythmRef.current;
    const chords = chordsRef.current;
    const melody = melodyRef.current;
    const container = containerRef.current;

    gsap.fromTo(rhythm, { opacity: 0.5, y: 100 }, {
      opacity: 1, y: 0, duration: 0.6, scrollTrigger: {
        trigger: container
      }
    })
    gsap.fromTo(chords, { opacity: 0.5, y: 100 }, {
      opacity: 1, y: 0, duration: 0.6, scrollTrigger: {
        trigger: container
      }
    })
    gsap.fromTo(melody, { opacity: 0.5, y: 100 }, {
      opacity: 1, y: 0, duration: 0.6, scrollTrigger: {
        trigger: container
      }
    })
  }, [])

  function tipMouseEnter(e:React.MouseEvent<HTMLImageElement, MouseEvent>):void {
    const tip = e.currentTarget;
    gsap.to(tip, { y: -35 ,duration: 0.5})
  }

  function tipMouseLeave(e:React.MouseEvent<HTMLImageElement, MouseEvent>):void {
    const tip = e.currentTarget;
    gsap.to(tip, { y: 0 ,duration: 0.5})
  }

  return (
    <div className="content2" ref={containerRef}>
      <img alt='tip' ref={rhythmRef} id="tip_rhythm" 
      src={require('../../../assets/tip_rhythm.png')}
        onMouseEnter={tipMouseEnter}
        onMouseLeave={tipMouseLeave}
      />
      <img alt='tip' ref={chordsRef} id="tip_chords"
      src={require('../../../assets/tip_chords.png')}
        onMouseEnter={tipMouseEnter}
        onMouseLeave={tipMouseLeave}
      />
      <img alt='tip' ref={melodyRef} id="tip_melody"
      src={require('../../../assets/tip_melody.png')}
        onMouseEnter={tipMouseEnter}
        onMouseLeave={tipMouseLeave}
      />
    </div>
  )
}