export function isInChina(cb: (arg0: boolean) => void) {
    var url = '//www.googleapis.com/youtube/v3/search';
    var xhr = new XMLHttpRequest();
    var called = false;
    xhr.open('GET', url);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && (xhr.status === 403 || xhr.status === 400)) {
            called = true;
            cb(false);
        }
    };
    xhr.send();
    // timeout 1s, this youtube API is very fast.
    setTimeout(function () {
        if (!called) {
            xhr.abort();
            cb(true);
        }
    }, 3000);
};
